<template>
    <div v-if="imgs && imgs.length>0" class="img-carousel" :style="{width:width ||'600px'}">
        <div class="img-carousel-big" :style="{height:width ||'600px'}">
            <!-- <img :src="(preUrl||'')+imgs[curImgIndex]"> -->
            <div class="bg_img"
                :style="{backgroundImage: 'url(' + (preUrl||'')+imgs[curImgIndex]+'!width_500px' + ')'}"></div>
        </div>
        <div class="img-carousel-list" ref="imgCarouselList">
            <div v-for="(img,idx) of imgs" :key="idx" @click="curImgIndex=idx" 
                :class="{'active':curImgIndex==idx}" :style="smallSize">
                <img :src="(preUrl||'')+img+'!width_250px'">
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            width:String,
            imgs: Array,
            preUrl: String,
        },
        data() {
            return {
                curImgIndex: 0,
                smallSize:{
                    width:'100px',
                    height:'100px'
                },
            }
        },
        watch: {
        },
        mounted() {
            this.setSmallSize();
        },
        computed: {
            
        },
        created: function () {

        },
        methods: {
            setSmallSize(){
                let listDom = this.$refs.imgCarouselList;
                // console.log(listDom);
                let tw = listDom ? listDom.clientWidth:100;
                // console.log(tw);
                // let width = (tw*0.8)/5;
                let width = (tw+10)/5 - 10;
                this.smallSize.width=width+'px';
                this.smallSize.height=width+'px';
            }
        }
    }
</script>
<style scoped>
    .img-carousel-big {
        width: 100%;
        /* width: 600px;
        height: 600px; */
    }

    .img-carousel-big img {
        width: 100%;
        height: 100%;
    }

    .img-carousel-list {
        width: 100%;
        margin-top: 20px;
        font-size: 0;
    }
    .img-carousel-list>*{
        cursor: pointer;
    }
    .img-carousel-list>div {
        display: inline-block;
        vertical-align: top;
        margin-right:10px;
        position: relative;
    }
    .img-carousel-list>div:last-child {
        margin-right: 0;
    }
    .img-carousel-list>div.active::before {
        content: '';
        position: absolute;
        top: 0; bottom: 0;
        left: 0; right: 0;
        border: 3px solid rgb(238, 24, 24);
    }
    .img-carousel-list img {
        width: 100%;
        height: 100%;
    }
    .bg_img {
        display: inline-block;
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position: center;
    }
</style>